<script setup>
const props = defineProps(["filter", "filtersStore"]);
const removeDots = (value) => {
  if (!value) return 0;
  if (value == "") return 0;
  return typeof value == 'string' ? value.replace(/\./g, "") : value;
};

const customValue = computed(() => {
  return {
    min: removeDots(
      props.filtersStore.selected_filters[props.filter.slug]?.value?.min
    ),
    max: removeDots(
      props.filtersStore.selected_filters[props.filter.slug]?.value?.max
    ),
  };
});


const setValue = (e, field) => {
  let value = parseInt(e)

  if (customValue.value[field] == value) {
    return;
  }
  if (typeof value !== "number") {
    return;
  }
  if (value == 0) {
    value = "";
  }

  let newValue = JSON.parse(JSON.stringify(customValue.value));
  newValue[field] = value;
  props.filtersStore.updateValue(
    props.filter,
    JSON.parse(JSON.stringify(newValue))
  );
};
</script>

<template>
  <div class="border-b border-b-slate-200 pb-3">
    <div class="px-3 mt-3">
      {{ filter.name }}
    </div>

    <div class="w-fit mx-4 flex gap-2 items-center mt-0 justify-between text-center">
      <w-input
        class="px-3 py-2 text-sm rounded-tl rounded-bl w-1/2"
        type="number"
        :name="`min-price`" :isPrice="true"
        :value="customValue.min"
        @input="(newVal) => setValue(newVal, 'min')" />
      <w-input
        class="px-3 py-2 text-sm rounded-tl rounded-bl w-1/2"
        type="number"
        :value="customValue.max"
        :name="`max-price`" :isPrice="true"
        @input="(newVal) => setValue(newVal, 'max')" />
    </div>
  </div>
</template>
